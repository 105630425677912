import 'twin.macro';

import { FloatingContext, FloatingFocusManager, FloatingPortal } from '@floating-ui/react';
import { ComponentPropsWithoutRef, forwardRef, ReactElement, ReactNode } from 'react';

import { usePopoverStateContext } from '../../Popover.context';
import { StyledContentWrapper } from './Content.styles';

export interface Props extends ComponentPropsWithoutRef<'div'> {
	arrow?: ReactElement;
	children: ReactNode;
	fullWidth?: boolean;
	maxHeight?: number;
	context?: FloatingContext;
	portalRootId?: string;
}

export const Content = forwardRef<HTMLDivElement, Props>(
	({ arrow, children, maxHeight, context, portalRootId, ...rest }, ref) => {
		const toggled = usePopoverStateContext();

		if (!toggled || !context) return null;

		return (
			<FloatingPortal id={portalRootId}>
				<FloatingFocusManager context={context} restoreFocus>
					<StyledContentWrapper ref={ref} {...rest}>
						{arrow}
						<div tw="overflow-y-auto" style={{ maxHeight }}>
							{children}
						</div>
					</StyledContentWrapper>
				</FloatingFocusManager>
			</FloatingPortal>
		);
	}
);

Content.displayName = 'Content';
