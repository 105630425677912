import { useMediaQuery } from '@cheqroom/hooks';
import { forwardRef, ForwardRefExoticComponent, HTMLAttributes, PropsWithChildren, RefAttributes } from 'react';

import { Stack } from '../Stack/Stack';
import { StyledCard, StyledStackedCards } from './Card.styles';
import { Footer } from './components/Footer/Footer';
import { Header } from './components/Header/Header';
import { Section } from './components/Section/Section';

interface ComponentComposition {
	Header: typeof Header;
	Footer: typeof Footer;
	Section: typeof Section;
}

type Padding = 'small' | 'medium' | 'large' | 'auto';

export interface Props extends HTMLAttributes<HTMLDivElement> {
	sectioned?: boolean;
	spacing?: Padding;
	maxWidth?: number;
	divided?: boolean;
	stacked?: boolean;
}

const Card = forwardRef<HTMLDivElement, PropsWithChildren<Props>>(
	({ children, sectioned = false, spacing = 'medium', divided = false, stacked = false, ...other }, ref) => {
		const mediaQuery = useMediaQuery('(max-width: 640px)');
		const autoSpacing = mediaQuery ? 'medium' : 'large';

		const CardContent = (
			<StyledCard
				ref={stacked ? undefined : ref}
				spacing={spacing === 'auto' ? autoSpacing : spacing}
				divided={divided}
				{...(stacked ? {} : other)}
			>
				{sectioned ? <Card.Section>{children}</Card.Section> : children}
			</StyledCard>
		);

		if (stacked) {
			return (
				<Stack vertical ref={ref} {...other}>
					{CardContent}
					{stacked && <StyledStackedCards />}
				</Stack>
			);
		}

		return CardContent;
	}
) as ForwardRefExoticComponent<PropsWithChildren<Props> & RefAttributes<HTMLDivElement>> & ComponentComposition;

Card.displayName = 'Card';
Card.Header = Header;
Card.Footer = Footer;
Card.Section = Section;

export { Card };
