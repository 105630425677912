import tw, { css, styled } from 'twin.macro';

import { Props } from './Labelled';

type StyledProps = Pick<Props, 'labelHidden' | 'required'>;

export const StyledLabel = styled.label<StyledProps>(({ labelHidden, required }) => [
	tw`inline-flex flex-row items-baseline mb-0 text-sm font-medium leading-5 text-gray-600`,

	labelHidden && tw`sr-only`,
	required ? tw`gap-1` : tw`gap-2`,
	required &&
		css`
			&:after {
				content: ' *';
				${tw`text-red-500`}
			}
		`,
]);
